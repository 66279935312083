import React from 'react';
import Layout from "../components/layout/layout";
import Seo from "../components/seo/seo"

export default ({location}) => (
  <Layout location={location}>
    <Seo title="El Último Mono"/>
    <div className="pv2 flex flex-column items-center sans-serif">
      <h1 className="db f3 fw1">Contacto</h1>
    </div>
    <div className="db center mw5 tc black link sans-serif pv2">
      <p className="mv0">Management: contratacionpolar@gmail.com</p>
      <p className="mv0">David Polar | 617308357</p>
      <p>elultimomonomusic@gmail.com</p>
      <a href="/dossier.pdf" title="El Último Mono" download>
        Dossier
      </a>
    </div>
  </Layout>
)
